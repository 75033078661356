import Slider from "react-slick";
import SmallSlider from "../SmallComponents/sliderSmall";
import Slider1Image from '../../assets/img/12.jpg'
import Slider2Image from '../../assets/img/2.jpg'
import Slider3Image from '../../assets/img/3.jpg'
export function HomeSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
    };

    let SliderData = [
        { image: Slider1Image, title: 'What is an ADU', description: 'ADU stands for "accessory dwelling unit." It is a secondary dwelling unit that is located on the same property as a single-family home. An ADU can be a detached structure, such as a guest house or a converted garage, or it can be a part of the main house, such as a basement apartment or a converted attic. ADUs are often smaller than the primary dwelling unit and are intended for use as a rental unit, a home office, a guest house, or a home for an aging parent or adult child' } , 
        { image: Slider2Image, title: 'How Does It Work', description: 'We help owners get building permits for ADUs, junior ADU or SB9 projects, including attached or detached ADUs, garage conversions and legalizations. We offer ADU design, planning and permitting services that take you from an idea to a ready-to-build project plan. Learn more about our approach and how we can help on your ADU project.' } , 
        { image: Slider3Image, title: 'How Much Does It Cost', description: 'The cost of building an ADU can vary significantly depending on a number of factors, including the size and complexity of the project, the materials used, the location, and the local building codes and regulations. We offer comprehensive ADU permitting service packages for ADUs, junior ADUs and SB9 projects.' } , 
    ]
    return (
        <>
            <div className="w-full Home__Slider">
                <Slider {...settings}>
                    {
                        SliderData && SliderData.map((item , index) => {
                          return  <SmallSlider image={item.image} title={item.title} description={item.description} />
                        })
                    }
                </Slider>
            </div>
        </>
    )
}