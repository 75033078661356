import Contact from "./Components/homeContact";
import HomeService from "./Components/homeService";
import { HomeSlider } from "./Components/homeSlider";
import WorkSteps from "./Components/workSteps";

export  default function Home() {
    return (
        <> 
            <HomeSlider />
            <HomeService />
            <WorkSteps />
            <Contact />
        </>
    )
}