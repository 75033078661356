import ServiceSmall from "./serviceSmall";
import Img from "../../assets/img/business.png";
import TitleProps from "./titleProps";
export default function HomeService() {

    const ServicesData = [
        { image: Img, title: "ADU", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' } , 
        { image: Img, title: "JADU", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' } , 
        { image: Img, title: "SB9", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' } 
    ]

    return (
        <>
            <div className="w-full Block__Padding bg-[#dddddd]" id="Services">
                <TitleProps title={'Service'} />
                <div className="max-w-screen-xl mx-auto flex max-lg:space-x-0 max-md:space-y-6 space-x-4 max-lg:flex-wrap max-xl:px-6">
                    {
                        ServicesData.map((item, index) => {
                            return <ServiceSmall count={item.count} image={item.image} title={item.title} desc = {item.desc} key={index} />
                        })
                    }
                </div>
            </div>
        </>
    )
}