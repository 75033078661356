export default function Footer () {
  return (
    <>
      <div className='w-full bg-main-blue py-5'>
        <div className='text-white font-bold font-main-bold text-center max-sm:text-[12px]'>
          Copyright © 2023 CA ADU. All rights reserved.
        </div>
      </div>
    </>
  )
}
