import TitleProps from './titleProps'
import WorksProps from './worksProps'
import Img from '../../assets/img/icon.svg'
import WorksPropsWithoutAfter from './workStepsWithoutAfter'
export default function WorkSteps() {
  const WorksData = [
    {
      image: Img,
      title: 'We document site conditions',
      desc: 'Get accurate 3D site measurements and as-built drawings that guide the design process of your residential construction project.'
    },
    {
      image: Img,
      title: 'We document site conditions',
      desc: 'Get accurate 3D site measurements and as-built drawings that guide the design process of your residential construction project.'
    },
    {
      image: Img,
      title: 'We document site conditions',
      desc: 'Get accurate 3D site measurements and as-built drawings that guide the design process of your residential construction project.'
    }
  ]

  return (
    <>
      <div id='Works' className='w-full   xl:w-full mx-auto px-4 sm:px-12 xl:px-0 Block__Padding'>
        <TitleProps title={'Our Process'} />
        <div className='flex flex-col max-w-screen-xl  mx-auto'>
          <div className='Work__Process'>
            <div className='w-[60%]  mx-auto md:w-2/6 flex justify-center items-start'>
              <img src={Img} className="" alt='' />
            </div>
            <div className='max-md:w-full w-4/6 flex flex-col justify-center'>
              <div className='Work__Process_title'>
                Research & Assessment
              </div>
              <div className='font-main-bold pl-[20px] sm:pl-[24px] text-[16px] md:text-[16px] lg:text-[22px] font-normal'>
                <ul className='pt-4 list-disc font-bold'>
                  <li>
                    Free Initial Consultation
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>We meet with the client to discuss the project, including the client’s needs, budget, and design preferences.</li>
                    </ul>
                  </li>
                  <li>
                    Site Visit
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>We may conduct a site visit to assess the location and any existing structures.</li>
                    </ul>
                  </li>
                  <li>
                    Research
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>We may conduct a site visit to assess the location and any existing structures.</li>
                    </ul>
                  </li>
                  <li>
                    Building Codes
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li> Make sure that your proposed ADU complies with all applicable building codes, including requirements for fire safety, structural integrity, and energy efficiency. You may need to hire a professional designer or architect to help you with this.</li>
                    </ul>
                  </li>
                  <li>
                    Feasibility Assessment & Proposal
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li> Research local zoning laws and building codes to determine what is allowed on your property and what is required to build an ADU. Consider the size, location, and design of the ADU, and develop a plan that meets your needs and complies with local regulations.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='Work__Process'>
            <div className='w-[60%]  mx-auto md:w-2/6 flex justify-center items-start'>
              <img src={Img} className="" alt='' />
            </div>
            <div className='max-md:w-full w-4/6 flex flex-col justify-center'>
              <div className='Work__Process_title'>
                Design
              </div>
              <div className='font-main-bold pl-[20px] sm:pl-[24px] text-[16px] md:text-[16px] lg:text-[22px] font-normal'>
                <ul className='pt-4 list-disc font-bold'>
                  <li>
                    Conceptual Design
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>We create a conceptual design for the project, which is a rough sketch of the layout, design, and overall aesthetic of the building. The client will have the opportunity to review and provide feedback on the conceptual design</li>
                    </ul>
                  </li>
                  <li>
                    Reviews & Revisions
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>Based on the feedback from the client, the architect will then create a more detailed schematic design of the building, which includes floor plans, elevations, and sections. The client will have another opportunity to review and provide feedback on the schematic design.</li>
                    </ul>
                  </li>
                  <li>
                    Final Design
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>Finalize the design of the building, including all of the details such as materials, finishes, and fixtures. The client will have the opportunity to review and approve the design.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='Work__Process'>
            <div className='w-[60%]  mx-auto md:w-2/6 flex justify-center items-start'>
              <img src={Img} className="" alt='' />
            </div>
            <div className='max-md:w-full w-4/6 flex flex-col justify-center'>
              <div className='Work__Process_title'>
                Plans & Permitting
              </div>
              <div className='text-[16px]  md:text-[18px] lg:text-[18px] font-main-bold font-medium text-main-blue'>
                Building an accessory dwelling unit (ADU) typically requires a number of permits, including a building permit, a plumbing permit, and an electrical permit. You may also need to obtain other permits, such as a grading permit, a mechanical permit, or a permit for any modifications that are being made to the exterior of the building.
              </div>
              <div className='font-main-bold pl-[20px] sm:pl-[24px] text-[16px] md:text-[16px] lg:text-[22px] font-normal'>
                <ul className='pt-4 list-disc font-bold'>
                  <li>
                    Plans
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>The architect will then prepare a set of construction documents, which includes detailed drawings and specifications that will be used by the contractor to build the project.</li>
                    </ul>
                  </li>
                  <li>
                    Submittal
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>You may need to obtain various permits and pay fees in order to build an ADU. These can include a building permit, a plumbing permit, and an electrical permit, among others. The specific permits and fees that you need to obtain will depend on the location and the specifics of the project.</li>
                    </ul>
                  </li>
                  <li>
                    Process Management
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>Hire a professional designer or architect to help you design the ADU and prepare the necessary plans and documents. Apply for any necessary permits, such as a building permit, a plumbing permit, or an electrical permit..</li>
                    </ul>
                  </li>
                  <li>
                    Approval & RTI
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='Work__Process'>
            <div className='w-[60%]  mx-auto md:w-2/6 flex justify-center items-start'>
              <img src={Img} className="" alt='' />
            </div>
            <div className='max-md:w-full w-4/6 flex flex-col justify-center'>
              <div className='Work__Process_title'>
                Construction
              </div>
              <div className='font-main-bold pl-[20px] sm:pl-[24px] text-[16px] md:text-[16px] lg:text-[22px] font-normal'>
                <ul className='pt-4 list-disc font-bold'>
                  <li>
                    Financing
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>Determine how you will finance the construction of the ADU. This may involve getting a home equity loan or a mortgage, or paying cash.</li>
                    </ul>
                  </li>
                  <li>
                    Contractor Selection & Bids
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>Hire a contractor to construct the ADU according to the approved plans and building codes. This may involve excavating the site, pouring the foundation, framing the structure, installing the utilities, and finishing the interior and exterior.</li>
                      <li>Once the construction documents are completed, the architect will work with the client and the contractor to ensure that the project is built according to the plans and specifications. The architect will also conduct regular site visits to monitor the progress of the project and ensure that it is built to the same standards as the designs.</li>
                    </ul>
                  </li>
                  <li>
                    Inspections
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>Have the ADU inspected by the appropriate agencies to ensure that it meets all applicable building codes and safety standards.</li>
                    </ul>
                  </li>
                  <li>
                    Occupancy
                    <ul className=' pl-[20px] font-normal text-[14px] sm:text-[18px]'>
                      <li>Once the ADU is complete, you may move in or rent it out to tenants. You may also need to obtain a certificate of occupancy from the local building department to confirm that the ADU is safe and fit for habitation.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
