import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { FaBars } from 'react-icons/fa'
export default function Header () {
  const [Open, setOpen] = useState(false)

  return (
    <>
      <div className='w-full bg-main-blue'>
        <div className='max-w-screen-xl mx-auto flex items-center justify-between py-3 max-xl:px-6'>
          <div className='text-white font-bold font-main-bold text-[24px] sm:text-[40px]'>
            CA ADU
          </div>
          <div className='max-[740px]:block hidden'>
            <FaBars
              className='text-white hover:text-main-orange cursor-pointer transition-all duration-500 text-[20px]'
              onClick={() => setOpen(!Open)}
            />
          </div>
          <div
            className={
              Open
                ? 'h-40 left-0 z-50 overflow-hidden  bg-main-blue w-full absolute top-14 sm:top-20 transition-all duration-500 max-[740px]:block hidden'
                : 'h-0  bg-main-blue w-full absolute left-0 z-50 overflow-hidden top-14 sm:top-20 transition-all duration-500'
            }
          >
            <ul className='px-6 flex flex-col space-y-2 justify-center'>
              <li className='Nav__Link'>
                <Link
                  className='test6'
                  activeClass='Active'
                  to=''
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </li>
              <li className='Nav__Link'>
                <Link
                  activeClass='Active'
                  to='Services'
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Services
                </Link>
              </li>
              <li className='Nav__Link'>
                <Link
                  activeClass='Active'
                  to='Works'
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Our Process
                </Link>
              </li>
              <li className='Nav__Link'>
                <Link
                  activeClass='Active'
                  to='Contact'
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className='max-[740px]:hidden'>
            <ul className='flex space-x-6 justify-end font-main-bold font-bold text-white'>
              <li className='Nav__Link'>
                <Link
                  className='test6'
                  activeClass='Active'
                  to=''
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </li>
              <li className='Nav__Link'>
                <Link
                  activeClass='Active'
                  to='Services'
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Services
                </Link>
              </li>
              <li className='Nav__Link'>
                <Link
                  activeClass='Active'
                  to='Works'
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Our Process
                </Link>
              </li>
              <li className='Nav__Link'>
                <Link
                  activeClass='Active'
                  to='Contact'
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
