export default function ServiceSmall(props) {
    return (
        <>
            <div className="md:w-1/2 lg:w-1/3 relative bg-service-bg border py-8 after:absolute after:w-full after:h-full after:top-0 after:left-0 after:bg-[#f5f5f5] after:opacity-30 transition-all duration-300 hover:-translate-y-2 hover:bg-main-orange group Services cursor-pointer rounded-xl overflow-hidden">
                <div className="flex justify-center items-center Services__Image">
                    <img src={props.image} className="Image_Hover w-[60px] h-[60px] transition-all duration-500" alt="" />
                </div>
                <div className="text-center my-3 text-[#000000] font-bold font-main-bold text-[26px] relative z-20 transition-all duration-500 group-hover:text-black">{props.title}</div>
                <div className="text-[#555555] transition-all text-[18px] font-medium duration-500 px-2 md:px-6 text-center group-hover:text-black">{props.desc}</div>
            </div>
        </>
    )
}