import ContactProps from './contactProps'
import TitleProps from './titleProps'
import { FaPhone } from 'react-icons/fa'
import { MdLocationOn, MdEmail } from 'react-icons/md'
export default function Contact() {
  const ContactData = [
    { icon: <FaPhone />, title: '(818) 900-5773', href: 'tel:8189005773' },
    {
      icon: <MdLocationOn />,
      title:
        ' 111 E Cedar Ave #202, Burbank, CA 91502 '
    },
    { icon: <MdEmail />, title: 'info@ca-adu.com', href: 'mailto:info@ca-adu.com' }
  ]

  return (
    <>
      <div className='w-full Block__Padding bg-[#dddddd]' id='Contact'>
        <TitleProps title={'Contact US'} />
        <div className='max-w-screen-xl max-xl:px-6 max-md:flex-col  mx-auto flex max-md:space-y-8 md:space-x-4'>
          {ContactData.map((item, index) => {
            return <ContactProps href={item.href} icon={item.icon} title={item.title} key={index} />
          })}
        </div>
      </div>
    </>
  )
}
