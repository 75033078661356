export default function ContactProps(props) {
    return (
        <>
            <a href={props.href} className="w-full sm:w-[80%]  md:w-1/3 bg-white relative hover:-translate-y-2 transition-all duration-300 cursor-pointer hover:bg-main-orange hover:text-black font-bold rounded-xl group h-48">
                <div className="h-24 flex justify-center items-center text-[50px] text-main-blue transition-all duration-300 group-hover:text-black font-main-bold">
                    {props.icon}
                </div>
                <div className="h-24 flex justify-center items-center text-center px-6">
                    {props.title}
                </div>
            </a>
        </>
    )
}