export default function SmallSlider(props) {
    return (
        <>
            <div className="max-sm:h-[370px] h-[600px] w-full">
                <img src={props.image} className="w-full h-full object-fill" alt="" />
                <div className="flex absolute w-full top-0 justify-center items-center h-full text-white">
                    <div className="max-w-screen-xl py-10 max-sm:h-full bg-black/50 rounded-xl">
                        <div className="text-center mb-4 text-[20px] sm:text-[36px] uppercase">{props.title}</div>
                        <div className="px-6 text-[14px] sm:text-[22px]">{props.description}</div>
                    </div>
                </div>
            </div>
        </>
    )
}